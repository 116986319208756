header#signup {
    height: 60px;
}

#dashboard, #newsletter {
    height: 100%;
    min-height: calc(100vh - 140px);
}
#newsletter {
    padding-top: 2rem;
}
.signup-form {
    width: 50%;
    min-width: 280px;
    margin: 40px auto;
    padding: 2rem 3rem;
    background-color: $color-white;
    text-align: center;
    &__header {     
        margin-bottom: 30px;
    }
    &__body {
        width: 80%;
        margin: 0 auto;
    }
}
@media screen and (max-width: 769px) {
    .signup-form {
        padding: 2rem;
    }
}

.form-group {
    margin: 20px 0 0;
}
.inline-group {
    display: inline-block;
    margin-right: 20px;
}
.form-submit {
    margin: 30px 0 0;
}
label{
    display: block;
    margin: 0px 0px 5px 0px;
    text-align: left;
}
input[type=text], input[type=email], input[type=password] {
    box-sizing: border-box;
    border: 1px solid #C2C2C2;
    box-shadow: 1px 1px 4px #EBEBEB;
    border-radius: 3px;
    padding: 7px;
    outline: none;
    width: 100%;
    font-size: 16px;
}
.form-error {
    color: $color-red;
}
#form-result {
    padding-top: 20px;
    color: $color-gold; 
}
textarea {
    width: 100%;
    min-height: 100px;
}
#news-form {
    padding: 1rem 0 3rem;
}

.newsletter-submenu {
    float: right;
}


#list-newsletter {
    width: 980px;
    margin: auto;
    padding: 10px;
}

table {
    margin-top: 20px;
    th {
        text-align: left;
    }
}

.description {
    padding: 10px;
    border: 1px solid $color-grey;
    background-color: $color-white;
    margin-top: 20px;
    width: 80%;
    float: left;
    min-height: 65px;
}
.actions {
    width: 15%;
    float: right;
    margin-top: 20px;
    padding-left: 20px;
    .btn {
        text-align: center;
        display: block;
        margin-bottom: 10px;
    }
}
#edit-form {
    display: none;
    clear: both;
    padding: 10px 0 40px;
    .form-group {
        clear: both;
        padding: 10px 0;
        label {
            float: left;
            width: 20%;
            padding-top: 5px;
        }
        input {
            float: right;
            width: 80%;
        }
        #mceu_11 {
            margin-top: 40px;
        }
    }

}
#checkbox-list {
    label {
        display: inline-block;
        margin-left: 5px;
    }
}
/*#checkbox-list {
     Cachons la case à cocher 
    [type="checkbox"]:not(:checked),
        [type="checkbox"]:checked {
        position: absolute;
        left: -9999px;
    }

     on prépare le label 
    [type="checkbox"]:not(:checked) + label,
        [type="checkbox"]:checked + label {
        position: relative;  permet de positionner les pseudo-éléments 
        padding-left: 25px;  fait un peu d'espace pour notre case à venir 
        cursor: pointer;     affiche un curseur adapté 
    }

     Aspect des checkboxes 
     :before sert à créer la case à cocher 
    [type="checkbox"]:not(:checked) + label:before,
        [type="checkbox"]:checked + label:before {
        content: '';
        position: absolute;
        left:0; top: 2px;
        width: 17px; height: 17px;  dim. de la case 
        border: 1px solid #aaa;
        background: #f8f8f8;
        border-radius: 3px;  angles arrondis 
        box-shadow: inset 0 1px 3px rgba(0,0,0,.3)  légère ombre interne 
    }

     Aspect général de la coche 
    [type="checkbox"]:not(:checked) + label:after,
        [type="checkbox"]:checked + label:after {
        content: '✔';
        position: absolute;
        top: 0; left: 4px;
        font-size: 14px;
        color: $color-gold;
        transition: all .2s;  on prévoit une animation 
    }
     Aspect si "pas cochée" 
    [type="checkbox"]:not(:checked) + label:after {
        opacity: 0;  coche invisible 
        transform: scale(0);  mise à l'échelle à 0 
    }
     Aspect si "cochée" 
    [type="checkbox"]:checked + label:after {
        opacity: 1;  coche opaque 
        transform: scale(1);  mise à l'échelle 1:1 
    }
}*/


.news {
    h2, a {
        color: inherit;
        transition: all .2s;
        &:hover {
            color: $color-black;
            cursor: pointer;
        }
    }
}


#edit_post {
    &:hover {
        color: $color-gold;
        cursor: pointer;
    }
}