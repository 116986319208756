
$jplayer-images-base-url: "../image/" !default;

.jp-type-playlist {
    background-color: $color-white;
    margin: 0 auto;
    max-width: 1024px;
}
.player-header {
    height: 140px;
    &__img {
        float: left;
        width: 140px;
        height: 140px;
        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
    &__controls {
        float: left;
        padding-left: 10px;
    }
}
@media screen and (min-width: 340px) {
    .player-header {
        height: 170px;
        &__img {
            float: left;
            width: 170px;
            height: 170px;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
        &__controls {
            float: left;
            padding-left: 10px;
        }
    }
}
@media screen and (min-width: 570px) {
    .player-header {
        height: 200px;
        //margin-top: -250px;
        //max-width: calc(100vw - 400px);
        &__img {
            float: left;
            width: 200px;
            height: 200px;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
        &__controls {
            float: left;
            padding-left: 30px;
            margin-top: 0px;
        }
    }
}
@media screen and (min-width: 768px) {
    .player-header {
        height: 250px;
        //margin-top: -250px;

        //max-width: calc(100vw - 400px);
        &__img {
            float: left;
            width: 250px;
            height: 250px;
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }
        &__controls {
            float: left;
            padding-left: 40px;
        }
    }
}
.jp-audio *:focus,
.jp-audio-stream *:focus,
.jp-video *:focus {
    /* Disable the browser focus highlighting. */
    outline:none;
}

.jp-audio button::-moz-focus-inner,
.jp-audio-stream button::-moz-focus-inner,
.jp-video button::-moz-focus-inner {
    /* Disable the browser CSS3 focus highlighting. */
    border: 0;
}
.jp-audio,
.jp-audio-stream,
.jp-video {
    font-size:16px;
    color: $font-grey;
    background-color: $color-white;
}
.jp-interface {
    position: relative;
    width:100%;
}

/* @group CONTROLS */
.jp-controls-holder {
    height: 85px;
    //width: 200px;
    margin-top: 10px;
    .jp-controls {
        height: 40px;
        margin-bottom: 10px;
    }
}
@media screen and (min-width: 570px) {
    .jp-controls-holder {
        height: 85px;
        width: 300px;
        margin-top: 30px;
        .jp-controls {
            height: 40px;
            margin-bottom: 10px;
        }
    }
}
@media screen and (min-width: 768px) {
    .jp-controls-holder {
        height: 100px;
        width: 300px;
        margin-top: 45px;
        .jp-controls {
            height: 40px;
            margin-bottom: 20px;
        }
    }
}
.jp-controls button {
    display:block;
    float:left;
    overflow:hidden;
    text-indent:-9999px;
    height: 34px;
    margin: 0 1px 2px 0;
    padding: 0;
    border:none;
    cursor: pointer;
}

.jp-type-playlist .jp-play {
    background: url("../img/player/play.svg") no-repeat;
    height: 40px;
    width: 40px;
}
.jp-type-playlist .jp-play:focus {
    background: url("../img/player/play.svg") no-repeat;
}

.jp-state-playing div.jp-type-playlist .jp-play {
    background: url("../img/player/pause.svg") no-repeat;
    height: 40px;
    width: 40px;
}
.jp-state-playing div.jp-type-playlist .jp-play:focus {
    background: url("../img/player/pause.svg") no-repeat;
}
.jp-type-playlist .jp-stop {
    background: url("../img/player/stop.svg") no-repeat;
    height: 30px;
    width: 30px;
    margin: 5px 0;
    display: none;
}
.jp-type-playlist .jp-stop:focus {
    background: url("../img/player/stop.svg") no-repeat;
}
@media screen and (min-width: 570px) {
    .jp-type-playlist .jp-stop {
        background: url("../img/player/stop.svg") no-repeat;
        height: 30px;
        width: 30px;
        margin: 5px 0;
        display: block;
    }
    .jp-type-playlist .jp-stop:focus {
        background: url("../img/player/stop.svg") no-repeat;
    }
}
.jp-type-playlist .jp-previous {
    background: url("../img/player/previous.svg") no-repeat;
    height: 30px;
    width: 30px;
    margin: 5px 10px;
}
.jp-type-playlist .jp-previous:focus {
    background: url("../img/player/previous.svg") no-repeat;;
}

.jp-type-playlist .jp-next {
    background: url("../img/player/next.svg") no-repeat;
    height: 30px;
    width: 30px;
    margin: 5px 10px;
}
.jp-type-playlist .jp-next:focus {
    background: url("../img/player/next.svg") no-repeat;
}
/* @end */

/* @group TOGGLES */
.jp-toggles {
    float: left;
    width: 100px;
    height: 30px;
    padding-left: 10px;
}
@media screen and (min-width: 570px) {
    .jp-toggles {
        float: right;
        width: 100px;
        height: 30px;
        padding-left: 0;
    }
}
.jp-toggles button {
    text-indent:-9999px;
    line-height:100%; /* need this for IE6 */
    border:none;
    cursor: pointer;
}
.jp-repeat {
    background: url("../img/player/repeat.svg") no-repeat;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    &:focus {
        background: url("../img/player/repeat.svg") no-repeat;
    }
}
.jp-state-looped .jp-repeat {
    background: url("../img/player/repeat-gold.svg") no-repeat;
    width: 25px;
    height: 25px;
    margin-right: 10px;
    &:focus {
        background: url("../img/player/repeat-gold.svg") no-repeat;
    }
}
.jp-shuffle {
    background: url("../img/player/shuffle.svg") no-repeat;
    width: 30px;
    height: 30px;
    &:focus {
        background: url("../img/player/shuffle.svg") no-repeat; 
    }
}
.jp-state-shuffled .jp-shuffle {
    background: url("../img/player/shuffle-gold.svg") no-repeat;
    width: 30px;
    height: 30px;
    &:focus {
        background: url("../img/player/shuffle-gold.svg") no-repeat;
    }
}
/* @end */

/* @group volume controls */
.jp-state-no-volume .jp-volume-controls {
    //display:none;
}
.volume-bar {
    display: none;
    height: 30px;
    width: 180px;
    float: left;
    margin-top: 3px;
    .jp-volume-controls {
        padding: 2px 0;
        .jp-volume-bar {
            //position: absolute;
            float: left;
            width: 60%;
            height: 20px;
            overflow: hidden;
            cursor:  pointer;
            background-color: $color-light-grey;
            .jp-volume-bar-value {
                background-color: $color-black;
                height: 20px;
            }
        }
    }
}
@media screen and (min-width: 570px) {
    .volume-bar {
        display: block;
        height: 30px;
        width: 180px;
        float: left;
        margin-top: 3px;
        .jp-volume-controls {
            padding: 2px 0;
            .jp-volume-bar {
                //position: absolute;
                float: left;
                width: 60%;
                height: 20px;
                overflow: hidden;
                cursor:  pointer;
                background-color: $color-light-grey;
                .jp-volume-bar-value {
                    background-color: $color-black;
                    height: 20px;
                }
            }
        }
    }
}
.jp-volume-controls button {
    //position: absolute;
    display:block;
    overflow:hidden;
    text-indent:-9999px;
    margin: 0;
    padding: 0;
    width: 16px;
    height: 11px;
    border:none;
    cursor: pointer;
}
.btn-volume-wrapper {
    width: 20%;
    text-align: center;
    float: left;
    line-height: 24px;
    .jp-mute {
        margin: 0 auto;
    }
    .jp-volume-max {
        margin: 0 auto;
    }
}
.jp-volume-controls .jp-mute {
    background: url("../img/player/volume-off.svg") no-repeat;
    width: 18px;
    height: 18px;
    &:focus {
        background: url("../img/player/volume-off.svg") no-repeat;
    }
}
.jp-state-muted .jp-volume-controls .jp-mute {
    background: url("../img/player/volume-off.svg") no-repeat;
    width: 18px;
    height: 18px;
    &:focus {
        background: url("../img/player/volume-off-gold.svg") no-repeat;
    }
}
.jp-volume-controls .jp-volume-max {
    background: url("../img/player/volume-up.svg") no-repeat;
    width: 18px;
    height: 18px;
    &:focus {
        background: url("../img/player/volume-up-gold.svg") no-repeat; 
    }
}
/* @end */

/* @group progress bar */
.progress-bar {
    background-color: $color-black;
    padding: 0 1%;
    height: 24px;
}
.jp-progress {
    height: 10px;
    width:80%;
    float: left;
    padding: 7px 0;
    .jp-seek-bar {
        background-color: $color-white;
        width:0px;
        height: 100%;
        cursor:pointer;
        .jp-play-bar {
            background-color: $color-light-grey;
            width:0px;
            height: 100%;
        }
    }
}
/* @end */

/* @group current time and duration */
.jp-current-time, .jp-duration {
    float: left;
    width: 10%;
    font-size:.8rem;
    color: $color-light-grey;
    text-align: center;
    line-height: 24px;
}
.jp-current-time {
    cursor: default;
}
.jp-duration {
    cursor: pointer;
}

/* @end */

/* @group playlist */
.jp-details {
    font-size: .8rem;
    text-align: left;
    padding-left: 10px;
    padding-top: 10px;
    display: block !important;
    .artist {
        text-transform: uppercase;
        color: $color-grey;
        font-size: .8rem;
    }
}
@media screen and (min-width: 321px) {
    .jp-details {
        font-size: .9rem;
    }
}
@media screen and (min-width: 380px) {
    .jp-details {
        font-size: 1rem;
    }
}
@media screen and (min-width: 768px) {
    .jp-details {
        margin-top: 0px;
        margin-left: 0;
        padding-left: 0;
        padding-top: 30px;
    }
}
.jp-playlist {
    ul {
        list-style-type:none;	
        font-size: 1rem;
        margin: 0;
        padding: 20px 10px 20px;
        li {
            padding: 1px 0;
            overflow: hidden;
            &:not(:last-child) {
                border-bottom: 1px solid #ccc;
            }
        }
    }
    .jp-duration {
        display: none;
    }
}
@media screen and (min-width: 375px) {
    .jp-playlist {
        .jp-duration {
            display: block;
        }
    }
}
@media screen and (min-width: 768px) {
    .jp-playlist {
        ul {	
            font-size: 1rem;
            padding: 20px 40px 30px;
            li {
                padding: 1px 0;
            }
        }
    }
}
div.jp-type-playlist div.jp-playlist a {
    color: $color-black;
    text-decoration:none;
    transition: all .2s;
    &:hover {
        color: $color-gold;
    }
    .jp-duration {
        color: $color-black;
        &:hover {
            color: $color-gold;
        }
    }
}
div.jp-type-playlist div.jp-playlist li.jp-playlist-current a {
    color: #000;
    font-weight: 600;
}
div.jp-type-playlist div.jp-playlist a.jp-playlist-item-remove {
    float:right;
    display:inline;
    text-align:right;
    margin-left:10px;
    font-weight:bold;
    color: $font-grey;
}
/* @end */

/* @group NO SOLUTION error feedback */
.jp-no-solution {
    padding:5px;
    font-size:.8em;
    background-color: $color-white;
    border-top:2px solid #554461;
    border-left:2px solid #554461;
    border-right:2px solid #180a1f;
    border-bottom:2px solid #180a1f;
    color: $font-grey;
    display:none;
}
.jp-no-solution a {
    color: $font-grey;
}
.jp-no-solution span {
    font-size:1em;
    display:block;
    text-align:center;
    font-weight:bold;
}
/* @end */


