// Colors
$color-black: #333;

$color-grey: #666;
$color-light-grey: #DCDCDC;

$font-grey: #acacac;

$color-gold: #963;
$color-red: #cd201f;

$color-white: #fff;

// Fonts
@import url('https://fonts.googleapis.com/css?family=Lustria|Open+Sans:400,600');

$main-font: 'Open Sans', Arial, sans-serif;
$minor-font: 'Lustria', serif;

.mb-2 {
    margin-bottom: 20px;
}

