@import 'variables';

@import 'base';
@import 'header';

@import 'font-awesome/font-awesome';

@import 'components/alert';
@import 'components/bg-video';
@import 'components/jplayer';
@import 'components/tables';

@import 'components/buttons';

@import 'news';

//@import 'player';



