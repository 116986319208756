#backToTop {
    display: none;
    color: $color-gold;
    font-size: .7rem;
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: .5rem .8rem;
    background-color: rgba($color-white, .2);
    border-radius: 5px;
    transition: all .2s;
    &:hover {
        cursor: pointer;
        background-color: rgba($color-black, .6);
        color: $color-white;
    }
}

#home {
    .header {
        position: relative;
        width: 100%;
        height: 80vh;
        filter: brightness(0.9);

        &__video {
            height: 60vh;
        }

        &__nav {
            position: fixed;
            top: 0;
            background-color: rgba($color-black, 1);
            width: 100%;
            overflow: hidden;
            z-index: 10;
            transition: all .2s;
            ul {
                position: relative;
                text-align: right;
                padding: 1.2rem 0;
                li {
                    display: inline-block;
                    a {
                        color: $color-white;
                        text-transform: uppercase;
                        padding: 0 1rem;
                        font-size: .85rem;
                        font-weight: 600;
                        transition: all .2s;
                        &.active, &:hover {
                            color: $color-gold;
                        }
                    }
                }
            }
            &--checkbox, &--button {
                display: none;
                position: absolute;
                top: 11px;
                right: 12px;
                width: 45px;
                height: 38px;
                z-index: 20;
            }
            &.scrolled {
                background-color: rgba($color-black, .9);
            }
        }

        &__title {
            position: absolute;
            text-align: center;
            top: 15vh;
            right: 0;
            left: 0;
            h1 {
                color: darken($color-grey, 5%);
                padding: 2rem 0;
                font-weight: 400;
                font-size: 2.25rem;
            }
            h2 {
                color: $color-white;
                padding: 1rem 0;
                font-family: $minor-font;
                font-size: 1.1rem;
                font-weight: 400;
            }
        }

        &__logos {
            position: absolute;
            margin: 50px auto;
            text-align: center;
            top: 32vh;
            right: 0;
            left: 0;
            img {
                padding: 0 .5rem;
            }
        }
    }
}
#news, #admin, #login {
    .header {
        position: relative;
        width: 100%;
        height: 60px;

        &__nav {
            position: fixed;
            top: 0;
            background-color: rgba($color-black, 1);
            width: 100%;
            overflow: hidden;
            z-index: 10;
            transition: all .2s;
            ul {
                position: relative;
                text-align: right;
                padding: 1.2rem 0;
                li {
                    display: inline-block;
                    a {
                        color: $color-white;
                        text-transform: uppercase;
                        padding: 0 1rem;
                        font-size: .85rem;
                        font-weight: 600;
                        transition: all .2s;
                        &.active, &:hover {
                            color: $color-gold;
                        }
                    }
                }
            }
            &--checkbox, &--button {
                display: none;
                position: absolute;
                top: 11px;
                right: 12px;
                width: 45px;
                height: 38px;
                z-index: 20;
            }
            &.scrolled {
                background-color: rgba($color-black, .9);
            }
        }
    }  
}
@media screen and (max-width: 769px) {
    #home, #news, #admin, #login {
        .header__nav ul li:not(:first-child) {
            display: none;
        }
        .header__nav.responsive {
            height: auto;
        }
        .header__nav ul {
            text-align: left;
            margin-left: 1rem;
        }
        .header__nav.responsive ul li:not(:first-child) {
            display: block;
            margin: .8rem 0;
        }
        .header__nav--button {
            display: block;
            background-color: $color-light-grey;
            &:hover {
                cursor: pointer;
                .header__nav--icon::before {
                    top: -.65rem;
                }
                .header__nav--icon::after {
                    top: .65rem;
                }
            }
        }
        .header__nav--icon {
            display: block;
            color: #fff;
            position: absolute;
            top: 18px;
            right: 7px;

            &, &::before, &::after {
                width: 30px;
                height: 2px;
                background-color: $color-black;
                display: inline-block;
            }
            &::before, &::after {
                content: "";
                position: absolute;
                left: 0;
                transition: all .2s;
            }
            &::before {
                top: -.55rem;
            }
            &::after {
                top: .55rem;
            }
        }

        .header__nav {
            &--checkbox:checked + &--button &--icon {
                background-color: transparent;
            }
            &--checkbox:checked + &--button &--icon::before {
                top: 0;
                transform: rotate(135deg);
            }
            &--checkbox:checked + &--button &--icon::after {
                top: 0;
                transform: rotate(-135deg);
            }
        }
    }
}


.footer {
    background: $color-black;
    text-align: center;
    padding: 1rem 0;
}
.footer__copyright {
    color: $color-white;
    font-size: .75rem;
    padding: 1rem 0;
}