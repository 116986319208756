.bg-video {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
    overflow: hidden;

    &__content {
        height: 100%;
        width: 100%;
        object-fit: cover;

    }
}
.bg-image {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: $color-gold url(../img/home-bg01.jpg) no-repeat center;
    background-size: cover;
    opacity: .8;
    z-index: -1;
}
.video-controls {
    position: absolute;
    bottom: 20px;
    left: 40px;
    width: 200px;
    img {
        display: inline-block;
        width: 20px;
        height: 20px;
        padding: .5rem;
        &:hover {
            cursor: pointer;
        }
    }
}
