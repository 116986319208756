*, *:before, *:after{
    margin: 0;
    padding: 0;
}
html {
    box-sizing: border-box;
    font-size: 16px;
}

a {
    text-decoration: none;
}
*:active, *:focus {
    outline:none;
}

body {
    position: relative;
    font-family: $main-font;
    font-weight: 400;
}
.wrapper {
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 10px;
    height: 100%;
}
@media screen and (min-width: 380px) {
    .wrapper {
        padding: 0 20px;
    }
}
.hidden {
    display: none !important;
}
.clearfix {
    clear: both;
}
@media screen and (max-width: 768px) {
    .mobile {
        display: block;
    }
    .large {
        display: none;
    }
}
@media screen and (min-width: 769px) {
    .mobile {
        display: none;
    }
    .large {
        display: block;
    }
}
.section__title {
    font-size: 1.9rem;
    color: $color-grey;
    text-align: center;
    padding: .6rem 0;
    margin-bottom: 30px;
}

.light-grey-bg {
    //background:url(../img/home-bg02.jpg)  repeat top left scroll;
    background-color: lighten($color-light-grey, 5%);
}
.dark-grey-bg {
    background:url(../img/home-bg03.jpg)  repeat top left scroll;
}
.album-title {
    text-align: center;
    margin-bottom: 30px;
    h3 {
        font-size: 2rem;
        color: $color-grey;
    }
    a {
        color: $color-gold;
    }
}
.compositions-title {
    margin-bottom: 20px;
    h3 {
        font-size: 1.4rem;
        color: $color-light-grey;
        font-weight: 400;
    }
    a {
        color: $color-gold;
    }
}
.album-footer {
    max-width: 1024px;
    margin: 10px auto 50px;
    p {
        font-size: .8rem;
    }
    a {
        color: $color-gold;
    }
}
@media screen and (min-width: 768px) {
    .album-footer {
        p {
            font-size: 1rem;
        }
    }
}
.section-contact {
    color: $font-grey;
    text-align: center;
    p {
        padding: 50px 0;
    }
    a {
        color: $color-gold;
        font-weight: 600;
        transition: all .2s;
        &:hover {
            text-decoration: underline;
        }
    }
}

@media screen and (min-width: 1250px) {
    .row {
        width: 1200px;
        height: 100%;
        margin-left: 60px;
        .col-3 {
            width: 31%;
            margin: 0 .8%;
            float: left;
        }
    }
}

.small {
    font-size: 80%;
}

body#news {
    h1 {
        font-size: 2rem;
    }
    h2 {
        color: $color-grey;
    }

    .news {
        background-color: $color-white;
        padding: 20px;
        margin: 20px auto;
    }
    .small {
        font-style: italic;
        color: #a0a0a0;
        padding: 10px 0;
        span {
            margin-left: 10px;
        }
    }

}

#admin .main-content section {
    min-height: 80vh;
    padding-top: 40px;
}
body#admin {
    h1 {
        font-size: 2rem;
    }
    h2 {
        color: $color-grey;
        padding-bottom: 40px;
    }
    .block {
        background-color: $color-white;
        padding: 20px;
        margin: 20px auto;
    }
    .small {
        font-style: italic;
        color: #a0a0a0;
        padding: 10px 0;
        span {
            margin-left: 10px;
        }
    }

}