/* The alert message box */
.alert {
    padding: 15px 20px;
    color: white;
    margin: 25px auto;
    text-align: center;
    display: inline-block;
}
.alert-success {
    background-color: rgba($color-gold, .5);
}
.alert-info {
    background-color: rgba($color-grey, .5);
}
.alert-warning {
    background-color: rgba($color-red, .5);
}



